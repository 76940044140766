import { BEE_ERROR_TYPE } from './constants';

const knownLoadWarningCodes = [
  1001, 1002, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 3000,
];
const knownSaveWarningCodes = [1000];

export default class BeeWarning extends Error {
  constructor(warning) {
    super(warning?.message || 'Unknown warning');

    this.name = 'BeeWarning';
    this.code = warning?.code || -1;
    this.detail = warning?.detail || '';
  }

  get type() {
    let value = BEE_ERROR_TYPE.UNKNOWN;

    const isLoadWarning = knownLoadWarningCodes.includes(this.code);
    const isSaveWarning = knownSaveWarningCodes.includes(this.code);
    const isFileManagerWarning = this.code > 3000 && this.code < 4000;
    if (isLoadWarning) {
      value = BEE_ERROR_TYPE.LOAD;
    } else if (isSaveWarning) {
      value = BEE_ERROR_TYPE.SAVE;
    } else if (isFileManagerWarning) {
      value = BEE_ERROR_TYPE.FILE_MANAGER;
    }

    return value;
  }

  toString() {
    return `BeeWarning (${this.code}): ${this.message}`;
  }
}
