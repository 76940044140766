import {
  breadcrumbsIntegration,
  browserTracingIntegration,
  init,
  setContext,
  setUser,
} from '@sentry/browser';
import { debugIntegration } from '@sentry/integrations';
import { isNumber } from 'lodash-es';
import Vue from 'vue';

import BeeWarning from 'app/partials/composer/bee-editor/BeeWarning';
import {
  currentCompany,
  currentUser,
  sentryConfig,
} from 'lib/document-helpers/document-meta';

/**
 * Inspect a caught exception to determine if we should ignore it rather than send to Sentry
 */
function shouldIgnoreError(error) {
  // ignore Bee warnings
  const isBeeWarning = error instanceof BeeWarning;
  if (isBeeWarning) return true;

  // ignore Axios errors if they have a 4xx response code
  const isAxiosError = error?.isAxiosError;
  const isUnexpectedError =
    isNumber(error?.response?.status) && error.response.status >= 500;
  if (isAxiosError && !isUnexpectedError) return true;

  return false;
}

export default () => {
  const { dsn, environment, release } = sentryConfig();
  const integrations = [
    browserTracingIntegration(),
    breadcrumbsIntegration({
      dom: {
        serializeAttribute: ['data-testid', 'aria-label'],
      },
    }),
  ];
  if (process.env.NODE_ENV === 'development') {
    integrations.push(debugIntegration());
  }

  init({
    Vue,
    dsn,
    environment,
    dist: environment,
    release,
    integrations,

    // 1.0 captures 100% of performance traffic
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],

    beforeSend(event, hint) {
      return shouldIgnoreError(hint?.originalException) ? null : event;
    },
  });

  const user = currentUser();
  const company = currentCompany();

  if (user && Object.prototype.hasOwnProperty.call(user, 'id')) {
    setUser(user);
  }

  if (company && Object.prototype.hasOwnProperty.call(company, 'id')) {
    setContext(company);
  }
};
